import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { Link } from 'react-router-dom';
import logo from '../myassets/logo.png';




function QTrainer() {
  const [surahInput, setSurahInput] = useState(''); // State for language input for prompt
  const [words, setWords] = useState([]);  // State to hold words from the database
  const [difficulty, setDifficulty] = useState('Random'); // Choose difficulty level for prompt
  const [lengthAyat, setLengthAyat] = useState('Random'); // Choose length of sentences
  const [includeTransliteration, setIncludeTransliteration] = useState(false);  // State to manage checkbox wether to include Transliteration
  const [isSubmitting, setIsSubmitting] = useState(false);  // State to track submission status
  const [selectedText, setSelectedText] = useState(''); // State for selected text
  const [lastGptResponse, setLastGptResponse] = useState(''); // New state for storing the last GPT response
// save the last 10 insight responses, and navigate through them
  const [insightResponses, setInsightResponses] = useState([]); // Array to store recent insight responses
  const [currentInsightIndex, setCurrentInsightIndex] = useState(-1); // Index to track the current response
// save the last 10 sentence request responses, and navigate through them
  const [sentenceResponses, setSentenceResponses] = useState([]); // Array to store recent sentence responses
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(-1); // Index to track the current response
  const selectedLanguage = 'Quran'; // Language is always set to Quran


  const apiUrl = process.env.REACT_APP_API_URL;
  console.log('API URL:', apiUrl);


  // Set the base URL from the environment variable
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  // Function to get the CSRF token for secure POST requests
  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  // Function to fetch words from the database based on selected language
  const fetchWords = async (language) => {
    try {
      const res = await axios.get('/api/get-words/', {
        params: { language: language }  // Send language as a query parameter
      });
      setWords(res.data.words);
    } catch (error) {
      console.error("There was an error fetching the words!", error);
    }
  };

  useEffect(() => {
    if (selectedLanguage) {  // Fetch words only if a language is selected
      fetchWords(selectedLanguage);  // Fetch words for the selected language
    }
  }, [selectedLanguage]);

  // UseEffect to track text selection
  useEffect(() => {
    const handleSelection = () => {
      const selected = window.getSelection().toString().trim();
      setSelectedText(selected); // Update state with selected text
    };

    document.addEventListener('mouseup', handleSelection);

    return () => {
      document.removeEventListener('mouseup', handleSelection);
    };
  }, []);

  // Function to save the selected word to the database
  const saveWordToDatabase = () => {
    if (!selectedText || !selectedLanguage) return; // Only proceed if there's selected text and selected language

    const csrftoken = getCookie('csrftoken');

    axios.post('/api/save-word/', {
      language: selectedLanguage, // Include the selected language
      word: selectedText
    }, {
      headers: {
        'X-CSRFToken': csrftoken
      }
    })
    .then((response) => {
      alert('Word saved successfully!');
      fetchWords(selectedLanguage);  // Fetch the updated word list after saving
    })
    .catch((error) => {
      console.error('There was an error saving the word!', error);
    });
  };

  // Function to fetch word insight from the server
  const fetchWordInsight = async () => {
    if (!selectedText) return; // Only proceed if there's selected text

    setIsSubmitting(true);
    try {
      const res = await axios.post('/api/word-insight/', {
        word: selectedText,
        language: selectedLanguage // Include the stored language in the request
      });

      const newResponse = res.data.answer;

        // Update the responses array
      setInsightResponses(prevResponses => {
        const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
        setCurrentInsightIndex(0); // Reset to the latest response
        return updatedResponses;
      });
    } catch (error) {
      console.error("There was an error fetching the word insight!", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);  // Disable the button and apply greyscale

    try {
      const res = await axios.post('/api/ask_gpt_ayat/', {
        surah_input: surahInput,
        difficulty: difficulty,
        length_ayat: lengthAyat,
        last_quran_response: lastGptResponse // Pass the last GPT response to the server
      });

      const newResponse = res.data.answer;

      // Update the responses array
      setSentenceResponses(prevResponses => {
        const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
        setCurrentSentenceIndex(0); // Reset to the latest response
        return updatedResponses;
      });

      setLastGptResponse(res.data.answer);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsSubmitting(false);  // Re-enable the button and remove greyscale
    }
  };


  // Helper function to truncate text to a specified length
  const truncateText = (text, length) => {
    if (text && text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  };


// Function to handle navigation with arrows
const handleInsightNavigate = (direction) => {
  if (direction === 'back' && currentInsightIndex < insightResponses.length - 1) {
      setCurrentInsightIndex(prevIndex => prevIndex + 1);
  } else if (direction === 'forward' && currentInsightIndex > 0) {
      setCurrentInsightIndex(prevIndex => prevIndex - 1);
  }
};

// Function to handle navigation for sentence responses
const handleSentenceNavigate = (direction) => {
  if (direction === 'back' && currentSentenceIndex < sentenceResponses.length - 1) {
      setCurrentSentenceIndex(prevIndex => prevIndex + 1);
  } else if (direction === 'forward' && currentSentenceIndex > 0) {
      setCurrentSentenceIndex(prevIndex => prevIndex - 1);
  }
};


const download_wordlist_CSV = () => {
  window.location.href = `${apiUrl}/api/download-words-csv/?language=${selectedLanguage}`;
};




const downloadInsightsHTML = () => {
  const styleContent = `
      body { font-family: Arial, sans-serif; line-height: 1.6; }
      .insight-output { padding: 20px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ddd; }
      .insight-output h2 { font-size: 24px; }
      .insight-output p { margin-bottom: 10px; }
      .insight-box { margin-bottom: 20px; padding: 15px; background-color: #fff; border: 1px solid #ddd; border-radius: 8px; }
      .insight-box h3 { margin-top: 0; }
  `;

  const insightsHtml = insightResponses.map((insight, index) => `
      <div class="insight-box">
          <h3>Insight ${index + 1}</h3>
          <p>${insight}</p>
      </div>
  `).join('');

  const htmlContent = `
      <html>
      <head>
          <meta charset="UTF-8">
          <style>${styleContent}</style>
      </head>
      <body>
          <div class="insight-output">
              <h2>Word Insights</h2>
              ${insightsHtml}
          </div>
      </body>
      </html>
  `;

  const blob = new Blob([htmlContent], { type: 'text/html' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'word_insights.html';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

<img src={logo} alt="LingoTrainer Logo" className="app-logo" />


const handleSubmitQuranVocabulary = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);  // Disable the button and apply greyscale

  try {
    const res = await axios.post('/api/ask-gpt-quran-vocabulary/', {
      surah_input: surahInput,
      difficulty: difficulty,
      include_transliteration: includeTransliteration,
      last_quran_response: lastGptResponse // Pass the last GPT response to the server
    });

    const newResponse = res.data.answer;

    // Update the responses array
    setSentenceResponses(prevResponses => {
      const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
      setCurrentSentenceIndex(0); // Reset to the latest response
      return updatedResponses;
    });

    setLastGptResponse(res.data.answer);
  } catch (error) {
    console.error("There was an error!", error);
  } finally {
    setIsSubmitting(false);  // Re-enable the button and remove greyscale
  }
};




  return (
    <div className = "container">
        {/* Header Section with Title and Logo */}
      <div className="title-header">
      <h1>LingoTrainer - Practice Ayat with GPT</h1>
      <img src={logo} alt="LingoTrainer Logo" className="title-logo" />
      </div>

      <form onSubmit={handleSubmit}>
        <textarea
          value={surahInput}
          onChange={(e) => setSurahInput(e.target.value)}
          rows="2"
          cols="10"
          placeholder="Type the Name or Number of Surah here..."
        />

        <br />
  
        <div className="form-row">
          <div className="form-group">
            <label>Difficulty of Ayat:</label>
            <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
              <option value="Random">Random</option>
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Challenging">Challenging</option>
            </select>
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Length of Ayat:</label>
            <select value={lengthAyat} onChange={(e) => setLengthAyat(e.target.value)}>
              <option value="Random">Random</option>
              <option value="short">Short</option>
              <option value="medium">Medium</option>
              <option value="long">Long</option>
            </select>
          </div>

          <div className="form-group">
            <label>Include Latin script transliteration:</label>
            <input
              type="checkbox"
              checked={includeTransliteration}
              onChange={(e) => setIncludeTransliteration(e.target.checked)}
            />
          </div>
        </div>


        <button
          type="button"
          onClick={handleSubmit} 
          disabled={isSubmitting}
          className={`button-spacing ${isSubmitting ? 'disabled' : ''}`}>
          Request Ayat
        </button>

        <button 
          type="button" 
          onClick={handleSubmitQuranVocabulary} 
          disabled={isSubmitting} 
          className={`button-spacing ${isSubmitting ? 'disabled' : ''}`}>
          Create Vocabulary
        </button>


      </form>

      <div className="output-container">
        <div className="response-output">
          {/* Section to display Sentence Responses with navigation */}
          {sentenceResponses.length > 0 && (
              <div>
                  <h2>Practice Ayat:</h2>
                  <div
                      id="response-output"
                      dangerouslySetInnerHTML={{ __html: sentenceResponses[currentSentenceIndex].replace(/\n/g, '<br />') }}
                  />

                  {/* Navigation Arrows */}
                  <div className="navigation-arrows">
                      <button
                          onClick={() => handleSentenceNavigate('back')}
                          disabled={currentSentenceIndex >= sentenceResponses.length - 1 || isSubmitting}
                      >
                           ←  
                      </button>
                      <button
                          onClick={() => handleSentenceNavigate('forward')}
                          disabled={currentSentenceIndex <= 0 || isSubmitting}
                      >
                           → 
                      </button>
                  </div>
              </div>
          )}
        </div>


        <div className="word-list">
          <h2>Stored Words</h2>
          <p>Language: {selectedLanguage}</p> {/* Display the selected language */}
          <ul>
            {words.map((word, index) => (
              <li key={index}>{word}</li>  // Display each word from the selected language
            ))}
          </ul>
          {/* Add the download button */}
          <button onClick={download_wordlist_CSV} className="download-csv-button">
            Download as CSV
          </button>
        </div>
      </div>




      {/* Buttons for saving and insights placed after the response-output and word-list */}
      {selectedText && (
        <div className="selected-text-buttons">
          <button
            onClick={fetchWordInsight}
            className={isSubmitting ? 'insight-button disabled' : 'insight-button'}
            disabled={isSubmitting}
          >
            Get Insight on "{truncateText(selectedText, 15)}"
          </button>
          <button
            onClick={saveWordToDatabase}
            className={isSubmitting ? 'save-word-button disabled' : 'save-word-button'}
            disabled={isSubmitting}
          >
            Store Word "{truncateText(selectedText, 15)}"
          </button>

        </div>
      )}

      {/* Section to display Word Insight with navigation */}
      {insightResponses.length > 0 && (
        <div className="insight-output">
          <h2>Word Insight</h2>
          <div dangerouslySetInnerHTML={{ __html: insightResponses[currentInsightIndex].replace(/\n/g, '<br />') }} />

          {/* Navigation Arrows */}
          <div className="navigation-arrows">
            <button
              onClick={() => handleInsightNavigate('back')}
              disabled={currentInsightIndex >= insightResponses.length - 1 || isSubmitting}
            >
              ← 
            </button>
            <button
              onClick={() => handleInsightNavigate('forward')}
              disabled={currentInsightIndex <= 0 || isSubmitting}
            >
              →
            </button>
          </div>

          {/* Add a button to download the insights as PDF */}
          <button onClick={downloadInsightsHTML} className="download-HTML-button">
            Download Insights as HTML
          </button>
        </div>
      )}
        {/* Navigation Links */}
        <nav>
        <Link to="/About">About</Link> | <Link to="/">Trainer</Link>
        </nav>
    </div>
    
  );
}

export default QTrainer;
